@charset "utf-8";

$link-color: #870C13;
$masthead-link-color: #870C13;

/* type scale */
$type-size-1 : 2.641em;  // ~39.056px
$type-size-2 : 2.153em;  // ~31.248px
$type-size-3 : 1.663em;  // ~25.008px
$type-size-4 : 1.45em;   // ~20px
$type-size-5 : 1.2em;      // ~16px
$type-size-6 : 0.95em;   // ~12px
$type-size-7 : 0.8875em; // ~11px
$type-size-8 : 0.625em;  // ~10px

$intro-transition  : none;
$global-transition : none;

$danger-color: #65090e;
$success-color: #3b5998;
$warning-color: #f0ad4e;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
